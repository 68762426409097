<template>
  <div class="page home retrievedDetails">
    <div class="plateBox">
      <div class="flex_b_c">
        <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>查看资源详情</span></div>
        <el-button type="primary" size="small" plain @click="back">返回</el-button>
      </div>
      <div class="flex_b_c">
        <div class="mainName">{{ detailsForm.resourceName }}</div>
        <el-button type="text" size="medium" @click="flagMore = !flagMore">{{ flagMore ? '展开' : '收起' }} <span
                :class="['flagMoreIcon', flagMore ? 'el-icon-arrow-down': 'el-icon-arrow-up']"></span>
        </el-button>
      </div>
      <div class="fieldBox" :style="{height: flagMore ? '3rem': 'auto'}">
        <div class="item">
          <div class="flex_l_t">
            <div class="field">资源简称：</div>
            <el-tooltip effect="dark" :content="detailsForm.resourceFoshot" placement="top-start">
              <div class="flex1 omit2">{{ detailsForm.resourceFoshot }}</div>
            </el-tooltip>
          </div>
        </div>
        <div class="item">
          <div class="flex_l_t">
            <div class="field">主题标题：</div>
            <el-tooltip effect="dark" :content="detailsForm.resourceTitle" placement="top-start">
              <div class="flex1 omit2">{{ detailsForm.resourceTitle }}</div>
            </el-tooltip>
          </div>
        </div>
        <div class="item">
          <div class="flex_l_t">
            <div class="field">成文时间：</div>
            <el-tooltip effect="dark" :content="detailsForm.writtenTime" placement="top-start">
              <div class="flex1 omit2">{{ detailsForm.writtenTime }}</div>
            </el-tooltip>
          </div>
        </div>
        <div class="item">
          <div class="flex_l_t">
            <div class="field">资源描述：</div>
            <el-tooltip effect="dark" :content="detailsForm.resourceDesc" placement="top-start">
              <div class="flex1 omit2">{{ detailsForm.resourceDesc }}</div>
            </el-tooltip>
          </div>
        </div>
        <div class="item">
          <div class="flex_l_t">
            <div class="field">资源来源：</div>
            <el-tooltip effect="dark" :content="detailsForm.resourceSource" placement="top-start">
              <div class="flex1 omit2">{{ detailsForm.resourceSource }}</div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="plateBox fieldBox" v-if="detailsForm.onCollection == 1">
      <div class="item" v-for="item in collInfoList">
        <div class="flex_l_t">
          <div class="field">{{ item.fieldName }}：</div>
          <el-tooltip v-if="item.fieldName == '藏品编号'" effect="dark"
                      :content="resources['generalNum'] ? resources['generalNum'] : resources['helpNum']"
                      placement="top-start">
            <div class="flex1 omit">{{ resources['generalNum'] || resources['helpNum'] }}</div>
          </el-tooltip>
          <el-tooltip v-else effect="dark" :content="resources[item.fieldEname] || resources[item.toFieldEname]"
                      placement="top-start">
            <div class="flex1 omit">{{ resources[item.fieldEname] || resources[item.toFieldEname] }}</div>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="plateBox">
      <el-tabs v-show="false" v-model="fileType" type="card" @tab-click="handleTabClick">
        <el-tab-pane :disabled="!(resourceTypes!=null&&resourceTypes.length>0 && (resourceTypes.indexOf('0') !== -1)) &&
                (fileType!= null && !(fileType==0))" label="三维模型" name="0"></el-tab-pane>

        <el-tab-pane :disabled="!(resourceTypes!=null&&resourceTypes.length>0 && (resourceTypes.indexOf('1') !== -1)) &&
                (fileType!= null && !(fileType==1))" label="图片" name="1"></el-tab-pane>

        <el-tab-pane :disabled="!(resourceTypes!=null&&resourceTypes.length>0 && (resourceTypes.indexOf('2') !== -1)) &&
                (fileType!= null && !(fileType==2))" label="音频" name="2"></el-tab-pane>

        <el-tab-pane :disabled="!(resourceTypes!=null&&resourceTypes.length>0 && (resourceTypes.indexOf('3') !== -1)) &&
                (fileType!= null && !(fileType==3))" label="视频" name="3"></el-tab-pane>

        <el-tab-pane :disabled="!(resourceTypes!=null&&resourceTypes.length>0 && (resourceTypes.indexOf('4') !== -1)) &&
                (fileType!= null && !(fileType==4))" label="出版物" name="4"></el-tab-pane>

        <el-tab-pane :disabled="!(resourceTypes!=null&&resourceTypes.length>0 && (resourceTypes.indexOf('5') !== -1)) &&
                (fileType!= null && !(fileType==5))" label="文献" name="5"></el-tab-pane>

        <el-tab-pane :disabled="!(resourceTypes!=null&&resourceTypes.length>0 && (resourceTypes.indexOf('6') !== -1)) &&
                (fileType!= null && !(fileType==6))" label="文件" name="6"></el-tab-pane>

        <el-tab-pane :disabled="!(resourceTypes!=null&&resourceTypes.length>0 && (resourceTypes.indexOf('7') !== -1)) &&
                (fileType!= null && !(fileType==7))" label="其他" name="7"></el-tab-pane>
      </el-tabs>
      <div>
        <div class="mainInfo" v-loading="loading">
          <el-carousel class="h100" :autoplay="false" indicator-position="none" type="card"
                       @change="changeImg" v-if="fileType == '1'">
            <el-carousel-item v-for="(item, index) in dataList" :key="item.id">
              <el-image :src="item.url" fit="contain" :preview-src-list="[item.url]">
                <el-image slot="placeholder" fit="contain" :src="require('@/assets/img/collPhoto.png')"></el-image>
                <el-image slot="error" fit="contain" :src="require('@/assets/img/collPhoto.png')"></el-image>
              </el-image>
            </el-carousel-item>
          </el-carousel>
          <el-row :gutter="20" class="h100" v-else>
            <el-col :span="showList ? 18 : 24" class="infoBox h100">
              <iframe v-if="fileType == '0'" id="mainIframe" ref="mainIframe" name="mainIframe"
                      :src="iframeSrc" frameborder="0" width="100%" class="iframe" height="100%"/>
              <iframe allowfullscreen="true" v-if="fileType != '0' && fileType != '1'" id="frame"
                      :src="$pdfPreviewUrl() + 'onlinePreview?url=' + fileUrl"
                      style="width: 100%;height: 100%;"></iframe>
            </el-col>
            <el-col v-show="showList" :span="6" class="h100">
              <el-table
                      :data="dataList"
                      size="small"
                      height="calc(100% - 60px)"
                      class="table pointer" ref="fileTable" @row-click="clickRow">
                <el-table-column prop="fileName" v-if="fileType != '0' || threeM == false"
                                 show-overflow-tooltip label="文件名称"></el-table-column>
                <div v-if="fileType == '0' && threeM == true">
                  <el-table-column prop="resourceName" show-overflow-tooltip
                                   label="关联三维列表"></el-table-column>
                  <el-table-column width="70" label="当前预览">
                    <template slot-scope="scope">
                      <i class="el-icon-check"
                         v-if="scope.row.threeMaster==1 || resourceId == scope.row.id"></i>
                    </template>
                  </el-table-column>
                  <el-table-column width="80" show-overflow-tooltip label="三维预览" fixed="right">
                    <template slot-scope="scope">
                      <el-button size="mini" type="primary" plain
                                 v-show="hasPermission('home:details:view')"
                                 @click="updateThreeMaster(scope.row.id,1,null)">查看
                      </el-button>
                    </template>
                  </el-table-column>
                </div>
              </el-table>
              <el-pagination class="text_center m_t1"
                             @size-change="sizeChangeHandle"
                             @current-change="currentChangeHandle"
                             :pager-count="3"
                             :current-page="pageNo"
                             :page-size="pageSize"
                             :total="total"
                             background
                             small
                             layout="total, prev, pager, next">
              </el-pagination>
            </el-col>
            <div v-show="fileType != '0' || threeM == true"
                 :class="['mainColor showListBtn', showList ? 'el-icon-arrow-right':'el-icon-arrow-left']"
                 @click="showTable()"></div>
          </el-row>
        </div>
        <div class="flex_b_c">
          <div>
            <el-tag
                    v-for="(item, index) in dynamicTags" :key="item.id" v-if="index < 3"
                    :closable="hasPermission('resourceDetails:index:delete') || item.labelFlag"
                    :disable-transitions="false"
                    @close="handleClose(item, index)">
              {{ item.labelName }}
            </el-tag>
            <el-popover v-if="dynamicTags.length > 3"
                        placement="right"
                        width="400"
                        trigger="click">
              <el-tag
                      v-for="(item, index) in dynamicTags" :key="item.id" v-if="index > 2"
                      :closable="hasPermission('resourceDetails:index:delete') || item.labelFlag"
                      :disable-transitions="false"
                      @close="handleClose(item, index)">
                {{ item.labelName }}
              </el-tag>
              <el-button slot="reference" size="small" class="moreBtn">...</el-button>
            </el-popover>
            <el-select class="input-new-tag" size="small"
                       v-model.trim="inputValue" v-if="inputVisible"
                       filterable
                       allow-create
                       default-first-option @change="changeIpt" style="margin-left: 5px">
              <el-option
                      v-for="item in tagOpt"
                      :key="item.id"
                      :label="item.labelName"
                      :value="item.id">
              </el-option>
            </el-select>
            <el-button type="primary"
                       v-if="!onLabel" plain
                       class="button-new-tag" size="small"
                       @click="showInput">
              +添加标签
            </el-button>
          </div>
          <div class="flex_r_c">
            <el-select v-if="fileType == '0'" size="small" class="m_r1" v-model="modelType"
                       @change="changeSelect"
                       placeholder="请选择" style="width: 150px">
              <el-option
                      v-for="item in modelTypeOpt"
                      :key="item.id"
                      :label="item.fieldName"
                      :value="item.id">
              </el-option>
            </el-select>
            <el-tooltip class="item" effect="dark" content="测量" placement="top">
              <el-button size="small" :type="threeIframeType ? '' : 'primary'"
                         v-if="hasPermission('admin:rangingThree') && fileType == 0"
                         @click="rangingThree" circle>
                <i class="icon-celiangleixing iconfont"/>
              </el-button>
            </el-tooltip>
            <el-button size="small" icon="el-icon-download"
                       circle
                       v-show="hasPermission('admin:resources:download') || borrowing && borrowing.applyStatus == 2 && !borrowing.onExpire && borrowing.validityFlag"
                       @click="downloadThree"></el-button>
            <el-popover class="info m_l1"
                        placement="top"
                        width="400"
                        trigger="hover">
              <div v-if="info!=null">
                <div v-if="info.fileName">文件名称：{{ info.fileName }}</div>
                <div v-if="info.fileSizeFormat">文件大小：{{ info.fileSizeFormat }}</div>
                <div v-if="info.fileDesc">文件描述：{{ info.fileDesc }}</div>
                <div v-if="info.shotUser">拍摄人：{{ info.shotUser }}</div>
                <div v-if="info.shotTime">拍摄日期：{{ info.shotTime }}</div>
                <div v-if="info.shotTask">拍摄任务：{{ info.shotTask }}</div>
                <div v-if="info.shotAddress">拍摄地点：{{ info.shotAddress }}</div>
                <div v-if="info.shotDevice">拍摄设备：{{ info.shotDevice }}</div>
                <div v-if="info.resourceWidth">图片宽度：{{ info.resourceWidth }}</div>
                <div v-if="info.resourceHeight">图片高度：{{ info.resourceHeight }}</div>
                <div v-if="info.focalLength">焦距：{{ info.focalLength }}</div>
                <div v-if="info.shutter">快门：{{ info.shutter }}</div>
                <div v-if="info.iso">ISO：{{ info.iso }}</div>
                <div v-if="info.aperture">光圈：{{ info.aperture }}</div>
              </div>
              <el-button slot="reference" size="small" type="text" icon="el-icon-info"></el-button>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="plateBox">
      <div class="plateTit flex_l_c"><span class="plateTitIcon mainBgColor"></span><span>相关资源</span></div>
      <el-tabs v-model="tabType" type="card" @tab-click="handleClick">
        <el-tab-pane label="三维资源" name="0"></el-tab-pane>
        <el-tab-pane label="图片资源" name="1"></el-tab-pane>
        <el-tab-pane label="视频资源" name="3"></el-tab-pane>
        <el-tab-pane label="音频资源" name="2"></el-tab-pane>
        <el-tab-pane label="出版物数据" name="4"></el-tab-pane>
        <el-tab-pane label="文献数据" name="5"></el-tab-pane>
        <el-tab-pane label="文件资料" name="6"></el-tab-pane>
        <el-tab-pane label="其他资源" name="7"></el-tab-pane>
      </el-tabs>
      <el-table
              :data="dataListType"
              size="small"
              height="calc(100vh - 400px)"
              class="table"
              @row-click="jump">
        <el-table-column
                prop="resource_name"
                show-overflow-tooltip
                label="资源名称">
        </el-table-column>
        <el-table-column
                prop="resource_desc"
                show-overflow-tooltip
                label="资源描述">
        </el-table-column>
        <el-table-column
                prop="collection_name"
                show-overflow-tooltip
                label="关联藏品名 ">
        </el-table-column>
      </el-table>
      <el-pagination class="text_center m_t2"
                     @size-change="sizeChangeHandleType"
                     @current-change="currentChangeHandleType"
                     :current-page="pageNoType"
                     :page-sizes="[10, 20, 50, 100]"
                     :page-size="pageSizeType"
                     :total="totalType"
                     background
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <ViewThreeForm ref="viewThreeForm"></ViewThreeForm>
    <ViewForm ref="viewForm"></ViewForm>
  </div>
</template>

<script>
  import ViewForm from '../gather/resource/components/viewForm' // 查看弹窗
  import ViewThreeForm from '../gather/resource/three/viewThreeForm'
  import {Base64} from 'js-base64'

  export default {
    components: {ViewForm, ViewThreeForm},
    props: {
      viewData: String,
    },
    data() {
      return {
        loading: false,
        borrowing: {}, // 借阅信息
        detailsForm: {},
        resources: {},
        dynamicForm: [],
        resourceId: '',
        flagMore: true,
        resourceInfo: {},
        fileType: 0,
        fileTypeSuffix: '',//文件后缀
        dataList: [],
        pageNo: 0,
        pageSize: 10,
        total: 0,
        info: {},
        fileUrl: '',
        fileList: [],
        modelType: '',
        modelTypeOpt: [],
        // 标签
        dynamicTags: [],
        inputVisible: false,
        inputValue: '',
        tagOpt: [],
        mtlUrl: '',
        objUrl: '',
        objFile: '',
        mtlFile: '',
        // 相关资源
        tabType: 0,
        dataListType: [],
        pageNoType: 1,
        pageSizeType: 10,
        iframeSrc: '/static/threeJs/examples/basics_obj.html',
        threeIframeType: true,
        totalType: 0,
        listType: '',
        collectionType: 0,// 藏品类型
        collectionMaster: false,
        resourceTypes: [],//类型有哪些
        onLabel: false,//是否显示标签
        showList: false,
        pageHistory: '', //从哪个页面来的
        threeM: false,
        returnPage: '',

        typeList: [],
        leaveList: [],
        collInfoList: [
          {
            fieldName: '藏品名称',
            fieldEname: 'collection_name',
            toFieldEname: 'collectionName'
          },
          {
            fieldName: '藏品编号',
            fieldEname: 'generalNum',
          },
          {
            fieldName: '藏品类型',
            fieldEname: 'collectionType',
          },
          {
            fieldName: '藏品分类',
            fieldEname: 'oneTypeName',
          },
        ],
      }
    },
    computed: {
      // 主题色
      defaultTheme() {
        return this.$store.state.config.defaultTheme
      },
    },
    watch: {
      defaultTheme(val) {
        this.changeMainColor(val);
      }
    },
    mounted() {
      this.threeIframeType = true
      if (this.viewData != 'BorrowForm') {
        this.init()
      }
      this.$nextTick(() => {
        this.changeMainColor(this.defaultTheme);
      })
      this.getSelectData()
    },
    methods: {
      getSelectData() {
        this.$axios(this.api.collection.listLevelSelect).then(data => {
          if (data.status) {
            this.leaveList = data.data
          }
        })
        this.$axios(this.api.collection.listSelect).then(data => {
          if (data.status) {
            this.typeList = data.data
          }
        })
      },

      // 数据初始化
      init(type, row) {
        // 借阅弹窗查看
        if (type == 'BorrowForm') {
          this.fileType = row.resource_type
          this.resourceId = this.fileType != null ? row.id : null;
          this.resourceInfo = row
          this.borrowing = row.borrowing
        } else {
          // 其它页面查看
          this.fileType = this.$route.query.resource_type || this.$route.query.resourceType
          this.resourceId = this.fileType != null ? this.$route.query.id : null;
          this.resourceInfo = this.$route.query;
          this.borrowing = this.resourceInfo.borrowing || this.resourceInfo.homeBorrowingEntity
        }
        this.resourcesPreviewFileType = this.getResourcePreviewFileFormat(this.fileType)
        this.info = {}
        this.onLabel = this.resourceId == null ? true : false
        this.$nextTick(() => {
          if (this.fileType == null) {
            if (this.resourceInfo.resource_types != null && this.resourceInfo.resource_types.length > 0) {
              this.collectionType = this.resourceInfo.resource_types[0]
            }
            this.getResourceId1(1);
            this.collectionMaster = true
            if (this.resourceInfo.resource_types != null && this.resourceInfo.resource_types.length > 0) {
              this.fileType = this.resourceInfo.resource_types[0]
              this.resourceTypes = this.resourceInfo.resource_types
              let a = {
                'index': this.fileType
              }
              this.onLabel = false
              this.handleTabClick(a)
            } else {
              this.onLabel = true
              this.fileType = -1
            }
            this.getImgResourceInfo()
          } else {
            if (this.fileType == 0) {
              this.getResourceInfo()
              this.getThreeResourceInfo()
            } else {
              this.getImgResourceInfo()
            }
          }
          this.refreshInfo()
          this.refreshList()
        })
      },
      // 获取三维资源信息
      getThreeResourceInfo() {
        this.$axios(this.api.dyyg.getResourceId + this.resourceId, "", "get").then((res) => {
          if (res.status) {
            this.detailsForm = res.data
            this.getDynamicForm()
          }
        })
      },
      // 获取除三维资源信息
      getImgResourceInfo() {
        this.$axios(this.api.dyyg.getResourcesInfo, {
          resourcesId: this.resourceId,
        }, 'get').then(data => {
          if (data.data.resourcesInfo) {
            this.detailsForm = data.data.resourcesInfo;
            this.resources = data.data.resourcesInfo;
          } else {
            this.detailsForm = {}
          }
          this.getTableByResourceName()
        })
      },
      //获取元数据信息
      getTableByResourceName() {
        this.dynamicForm = [];
        var that = this;
        this.$axios(this.api.dyyg.getTableByResourceName, {
          resourceName: '新增资源',
        }, 'get').then(res => {
          if (res.status) {
            res.data.data.map(item => {
              if (item.fieldEname != 'collectionId' && item.fieldEname != 'collection_id') {
                if (this.detailsForm[item.fieldEname] == null) {
                  this.$set(this.detailsForm, item.fieldEname, "")
                } else {
                  item.value = this.detailsForm[item.fieldEname];
                }
                this.dynamicForm.push(item);
              }
            })
          }
        })
      },
      toHump(name) {
        return name.replace(/\_(\w)/g, function (all, letter) {
          return letter.toUpperCase();
        });
      },
      getSearchList(value, date, key, nameKey) {
        let slDate = date.filter(item => {
          return item[key] == value
        })
        if (slDate.length) {
          return slDate[0][nameKey]
        }
      },

      getDynamicForm() {
        var that = this;
        let parma = {
          masterTableId: "946049961995272192",
          pageType: 1
        }
        that.$axios(this.api.dyyg.getTableByType, parma, "get").then((res) => {
          if (res.status) {
            that.dynamicForm = res.data
            that.dynamicForm.forEach(function (v) {
              v.fieldEname = that.toHump(v.fieldEname)
            })
          }
        })
      },
      // tab切换
      handleTabClick(val) {
        this.dataList = []
        this.pageNo = 1
        this.currentPage = 1
        this.fileTypeSuffix = ''
        this.collectionType = val.index
        if (val.index == 0) {
          this.getResourceId1()
        } else {
          this.threeM = false
          this.refreshInfo()
        }
      },
      // 获取资源id
      getResourceId1(type) {
        this.$axios(this.api.dyyg.getResourceId1, {
          'collectionId': this.resourceInfo.id,
          'resourceType': this.collectionType
        }, 'get').then(res => {
          if (res.status) {
            this.resourceId = res.data[0]
            this.threeM = res.data[1]
            this.showList = this.threeM == true ? true : this.showList
            if (type == null) {
              this.getResourceInfo(this.resourceId)
            }
            this.labelList()
            this.queryLabelNoRe()
            this.refreshList()
            this.getImgResourceInfo()
          }
        })
      },
      // 展示资源列表
      showTable() {
        this.showList = !this.showList
        this.$nextTick(() => {
          this.$refs.fileTable.doLayout();
        })
      },
      getCollectionThree() {
        this.dataList = []
        this.$axios(this.api.dyyg.getCollectionThree, {
          current: this.pageNo,
          size: this.pageSize,
          resourceType: this.collectionType,
          collectionId: this.resourceInfo.id
        }, "get").then((res) => {
          if (res.status) {
            this.dataList = res.data.records
            this.total = parseInt(res.data.total)
          }
        })
      },
      // 数据详情查询
      refreshInfo() {
        var type = this.collectionType;
        if (!this.collectionMaster) {
          type = 100;
        }
        if (this.threeM && (this.resourceId != null || this.resourceId != '')) {
          this.getCollectionThree()
          return
        }
        this.$axios(this.api.dyyg.getResourceByCollectionId, {
          'current': this.pageNo,
          'size': this.pageSize,
          'collectionId': this.resourceInfo.id,
          'resourceType': type,
          'resourceId': this.resourceId,
          'modelType': this.modelType,
          'digitalCollectionId': this.resourceInfo.digitalCollectionId,
        }, 'get').then(res => {
          if (res.status) {
            this.total = parseInt(res.data.total);
            this.dataList = res.data.records;
            this.info = this.dataList[0];
            this.fileUrl = encodeURIComponent(Base64.encode(this.info.url)).replaceAll("\\+", "%20")
            if (res.data.records != null && res.data.records.length > 0 && res.data.records[0].resourceId != null) {
              this.resourceId = res.data.records[0].resourceId
            }
            this.labelList()
            this.queryLabelNoRe()
            if (this.info != null && this.fileType != 0) {
              this.getFileType(this.info.url);
              this.getResourceFilesWatermark()
            }
          }
        })
      },
      // 获取资源详情信息
      getResourceInfo(resourceId) {
        let param = {
          resourceId: this.resourceId == null ? resourceId : this.resourceId
        }
        this.$axios(this.api.dyyg.showThreeResource, param, 'get').then(data => {
          if (data && data.status) {
            this.fileList = data.data.stringMapMap
            this.resources = data.data.resources
            this.getFieldByResourceName();
          }
        })
      },
      // 下载
      downloadThree() {
        this.$message.info("资源文件在进行下载中，请勿重复点击")
        const a = document.createElement('a')
        if (0 == this.fileType) {
          // stl 下载方式
          if (this.inputForm.fileFormat === 'stl') {
            const stlUrl = this.inputForm.url
            const stlName = this.inputForm.fileName
            fetch(stlUrl).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
              a.href = URL.createObjectURL(blob)
              a.download = stlName, // 下载文件的名字
                      document.body.appendChild(a)
              a.click()
              this.saveLog(stlName)
            })
            return
          }
          if ((this.objFile == null || this.objFile.url == null) && (this.mtlFile == null || this.mtlFile.url == null)) {
            this.$message.error("当前资源未存在文件或文件损坏！")
            return
          }
          this.saveLog(this.mtlFile.fileName)
          this.saveLog(this.objFile.fileName)
          let urls = [this.mtlFile.url, this.objFile.url]
          this.imgFile.map(item => {
            this.saveLog(item.fileName)
            urls.push(item.url)
          })
          this.$nextTick(() => {
            this.$axios(this.api.dyyg.downThreeZip, urls, "post", 'blob').then((res) => {
              if (res.status) {
                let blob = new Blob([res.data], {type: 'application/zip'})
                let link = document.createElement('a')
                link.download = decodeURI('三维资源')
                link.href = window.URL.createObjectURL(blob)
                link.click()
              }
            })
          })
        } else {
          if (this.info == null || this.info.url == '') {
            this.$message.error("当前资源未存在文件或文件损坏！")
            return
          }
          if (this.fileType == '1' || this.fileTypeSuffix == 'pdf') {
            //水印下载
            this.$axios(this.api.dyyg.downloadResources, {
              'fileId': this.info.id,
            }, 'get', 'blob').then(res => {
              let blob
              if (this.fileType == '1') {
                blob = new Blob([res.data], {type: 'image/jpeg'})
              } else {
                blob = new Blob([res.data], {type: 'application/pdf'})
              }
              let link = document.createElement('a')
              link.download = decodeURI(this.info.fileName)
              link.href = window.URL.createObjectURL(blob)
              link.click()
              this.saveLog(this.info.fileName)
            })
          } else {
            const url = this.info.url
            fetch(url).then(res => res.blob()).then(blob => {
              a.href = URL.createObjectURL(blob)
              a.download = this.info.fileName;
              document.body.appendChild(a)
              a.click();
              this.saveLog(this.info.fileName)
            })
          }
        }


      },
      // 保存日志
      saveLog(fileName) {
        this.$axios(this.api.dyyg.saveFileLog, {
          'fileId': this.info.id,
          'operationType': '下载文件',
          moduleType: 1,
          'resourceId': this.resourceId,
          'fileName': fileName,
        }, 'post').then()
      },
      //标签展示
      labelList() {
        this.$axios(this.api.dyyg.queryLabelRe, {
          'resourceId': this.resourceId,
        }, 'get').then(res => {
          if (res.status) {
            this.dynamicTags = res.data
          }
        })
      },
      //标签匹配
      queryLabelNoRe() {
        this.$axios(this.api.dyyg.queryLabelNoRe, {
          'resourceId': this.resourceId,
        }, 'get').then(res => {
          if (res.status) {
            this.tagOpt = res.data
          }
        })
      },
      // 关闭标签
      handleClose(item, index) {
        this.$confirm(`确定删除标签吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios(this.api.dyyg.removerByLabelIdAndResourceId, {
            'labelId': item.id,
            'resourceId': this.resourceId,
          }, 'get').then(res => {
            if (res.status) {
              this.$message.success("操作成功")
            } else {
              this.$message.success(res.msg)
            }
            this.labelList()
            this.queryLabelNoRe()
            this.refreshList()
          })
        })
      },
      // 显示添加标签输入框
      showInput() {
        if (this.resourceId == null) {
          this.$message.warning("当前暂无资源！")
          return
        }
        this.inputVisible = true;
      },
      changeIpt(val) {
        if (val.trim().length == 0) {
          this.$message('请输入标签内容')
          return false
        }
        let item = this.tagOpt.filter(v => v.id == val)
        let labelType = this.$dictUtils.getDictLabel("resource_type", this.fileType, '')
        let obj = {}
        if (item.length != 0) {
          obj = {
            lableName: item[0].labelName,
            resourceId: this.resourceId,
            labelType
          }
        } else {
          obj = {
            lableName: val,
            resourceId: this.resourceId,
            labelType
          }
        }
        this.$axios(this.api.dyyg.saveLabel, obj, 'post').then(res => {
          if (res.status) {
            this.$message.success('新增标签成功')
            this.labelList();
          } else {
            this.$message.error(res.msg)
          }
          this.queryLabelNoRe();
        })
        this.inputValue = '';
        this.inputVisible = false;
      },
      // 水印
      getResourceFilesWatermark() {
        let first = this.info.url.lastIndexOf(".");
        let nameLength = this.info.url.length;
        let fileFormat = this.info.url.substring(first + 1, nameLength).toLowerCase();
        let fileType = ['gif', 'jpg', 'jpeg', 'png', 'pdf']
        if (fileType.indexOf(fileFormat) == -1) {
          return
        }
        this.loading = true
        this.$axios(this.api.dyyg.getResourceFilesWatermark, {
          id: this.info.id,
        }, 'get').then(res => {
          if (res.status && res.data) {
            if (res.data.url.indexOf('http') > -1) {
              this.$set(this.info, 'url', res.data.url)
              this.fileUrl = encodeURIComponent(Base64.encode(this.info.url)).replaceAll("\\+", "%20")
            }
          }
          setTimeout(() => {
            this.loading = false
          }, 1000)
        })
      },
      // 图片切换
      changeImg(val) {
        this.info = this.dataList[val];
        this.getFileType(this.info.url);
        this.getResourceFilesWatermark()
      },
      // 点击表格行
      clickRow(val) {
        this.currentPage = 1;
        this.info = val;
        this.fileUrl = encodeURIComponent(Base64.encode(this.info.url)).replaceAll("\\+", "%20")
        this.getFileType(this.info.url);
        this.getResourceFilesWatermark()
      },
      // 每页数
      sizeChangeHandle(val) {
        this.pageSize = val
        this.pageNo = 1
        this.refreshInfo()
      },
      // 当前页
      currentChangeHandle(val) {
        this.pageNo = val
        this.refreshInfo()
      },
      // 获取文件后缀识别文件类型
      getFileType(data) {
        let first = data.lastIndexOf(".");//取到文件名开始到最后一个点的长度
        let namelength = data.length;//取到文件名长度
        this.fileTypeSuffix = data.substring(first + 1, namelength);//截取获得后缀名
        if (this.resourcesPreviewFileType.indexOf(this.fileTypeSuffix) == -1) {
          this.$message.warning("该文件不支持预览，请下载后本地查看")
        }
      },
      updateThreeMaster(id, type, threeMasterPut) {
        this.$axios(this.api.dyyg.updateThreeMaster, {
          'resourceId': id,
          'type': type,
          'threeMasterPut': threeMasterPut,
          'collectionId': this.resourceInfo.id
        }, "get").then((res) => {
          if (res.status) {
            if (type == 2) {
              this.$message.success("操作成功")
              this.getCollectionThree()
            } else if (type == 1) {
              this.getResourceId1();
            }
          }
        })
      },
      // 获取中高低模
      getFieldByResourceName() {
        var that = this;
        let parma = {
          resourcesId: this.resourceId
        }
        that.$axios(this.api.dyyg.resourceModel, parma, "get").then((res) => {
          if (res.status) {
            that.modelTypeOpt = res.data
            if (that.modelTypeOpt != null && that.modelTypeOpt.length > 0) {
              that.modelType = that.modelTypeOpt[that.modelTypeOpt.length - 1].id
              this.refreshInfo()
              that.changeSelect(that.modelType)
            }
          }
        })
      },
      changeSelect(id, iframeSrc) {
        var that = this
        let newarr = this.fileList[id];
        if(!iframeSrc) {
          this.threeIframeType = true
        } else {
          this.threeIframeType = false
        }
        if (id == '955785177131384832') {
          this.$message('预览高模文件，请保持客户端内存充足')
        }
        if (!newarr) {
          this.$message('无三维文件，请上传三维文件或设置主显')
          this.resourceExists = true;
          return;
        }
        let iframeData
        if (newarr.obj && newarr.mtl && newarr.img) {  // stl模型
          this.iframeSrc = iframeSrc ? iframeSrc : '/static/threeJs/examples/basics_obj.html'
          this.inputForm = newarr.obj
          that.objFile = newarr.obj
          that.mtlFile = newarr.mtl
          that.imgFile = newarr.img
          iframeData = {
            mtlUrl: newarr.mtl.url,
            objUrl: newarr.obj.url,
            imgUrl: newarr.img,
          }
        } else if (newarr.stl) {  // stl模型
          this.iframeSrc = '/static/threeJs/examples/basics_stl.html'
          this.inputForm = newarr.stl
          iframeData = {
            stlUrl: newarr.stl.url,
          }
        } else {
          this.$message('缺失文件')
          this.resourceExists = true;
          return;
        }
        // 使用ref 获取 dom
        const mapFrame = this.$refs['mainIframe']
        // 因为iframe页面打开就已经加载 获取接口成功后刷新他
        mapFrame.contentWindow.location.reload()
        if (mapFrame.attachEvent) {
          // 兼容浏览器判断 // iframe的加载完成函数
          mapFrame.attachEvent('onload', function () {
            const iframeWin = mapFrame.contentWindow
            //传递参数
            iframeWin.postMessage(iframeData, '*')
          })
        } else {
          // iframe的加载完成函数
          mapFrame.onload = function () {
            const iframeWin = mapFrame.contentWindow
            //传递参数
            iframeWin.postMessage(iframeData, '*')
          }
        }
        return
      },

      // 测距
      rangingThree() {
        this.threeIframeType = !this.threeIframeType
        if (!this.threeIframeType) {
          this.changeSelect(this.modelType, '/static/threeJs/examples/test_obj.html')
        } else {
          this.changeSelect(this.modelType)
        }
      },

      // 数据列表查询
      refreshList() {
        this.$axios(this.api.dyyg.getResourcesListByLabelOrGroup, {
          resourceId: this.resourceId,
          resourceType: this.tabType,
          current: this.pageNoType,
          size: this.pageSizeType,
        }, 'get').then(data => {
          if (data.status) {
            this.dataListType = data.data.records;
            this.totalType = parseInt(data.data.total);
          }
        })
      },
      jump(row) {
        if (row.resource_type == 0) { //三维
          this.$refs.viewThreeForm.init(row, null, true);
        } else {
          this.$refs.viewForm.init(row.id, row.resource_type, this.threeModuleId, null, true);
        }
      },
      handleClick(val) {
        this.pageNoType = 0
        this.refreshList()
      },
      // 每页数
      sizeChangeHandleType(val) {
        this.pageSizeType = val
        this.pageNoType = 1
        this.refreshList()

      },
      // 当前页
      currentChangeHandleType(val) {
        this.pageNoType = val
        this.refreshList()
      },
      // 返回
      back() {
        this.returnPage = 1
        this.threeM = false
        if (this.viewData == 'BorrowForm') {
          this.$emit('getData')
        } else {
          this.$router.push({path: sessionStorage.getItem('dizhi')})
        }
      },
    },

    beforeRouteLeave(to, from, next) {
      if (!this.returnPage) {
        sessionStorage.removeItem('homeSetOpt')
        sessionStorage.removeItem('listSearch')
      }
      next();
    }
  }
</script>

<style scoped>
  .flagMoreIcon {
    font-size: 14px !important;
  }

  .el-carousel >>> .el-carousel__container {
    height: 100% !important;
  }

  .el-carousel__item >>> .el-image {
    height: 100%;
  }

  .el-carousel__item {
    text-align: center;
  }

  .word-wrap, .xlsx-wrap {
    overflow: auto;
  }

  .word-wrap >>> img, .xlsx-wrap >>> table {
    width: 100% !important;
  }

  .xlsx-wrap >>> tr {
    border-bottom: 1px solid #DCDCDC;
  }

  .xlsx-wrap >>> td {
    padding: 6px;
    text-align: center;
  }

  .info .el-button--small {
    font-size: 34px;
  }

  .button-new-tag {
    margin-left: 5px;
  }

  .moreBtn {
    margin-left: 5px !important;
  }
</style>
